<template>
  <div class="wrapper">
    <item-field :fields="itemObject.itemSchema.field"></item-field>
  </div>
</template>

<script>
import { submit } from "@/api/base";
import ItemField from "./ItemField.vue";

export default {
  name: "test402",
  components: { ItemField },
  data() {
    return {
      content: "<p>你好</p>",
      itemObject: {},
      itemFields: [],
      multiInput: {},
      multiComplex: {},
      fields: [],
      brandOptions: [],
      filterArray: [],
      newArray: [],
      filterVal: "",
      initOption: [],
    };
  },
  mounted() {
    this.getContent();
  },
  methods: {
    async getContent() {
      const { data } = await submit("/api/product/getAddPlatProductSchema");
      if (!data) {
        return;
      }
      let xmlObject = {};
      try {
        xmlObject = this.$x2js.xml2js(data);
        this.itemObject = xmlObject || {};
        this.itemFields = (this.itemObject.itemSchema || {}).field || [];
      } catch (ex) {
        console.log(ex);
        return;
      }
      this.initFields(this.itemFields);
      if (this.itemFields.length > 0) {
        this.itemObject.itemSchema.field = this.itemFields;
      }
    },
    initFields(fields) {
      fields.forEach((item) => {
        if (item._type == "label") {
          return true;
        }
        if (!("rules" in item) || !(item.rules instanceof Object)) {
          item.rules = {};
        }
        if (!("rule" in item.rules)) {
          item.rules.rule = [];
        } else if (!(item.rules.rule instanceof Array)) {
          if (Object.keys(item.rules.rule).length == 0) {
            item.rules.rule = [];
          } else {
            item.rules.rule = [item.rules.rule];
          }
        }
        if (
          (item._type == "input" || item._type == "singleCheck") &&
          !("value" in item)
        ) {
          item.value = "";
        }
        if (item._type == "singleCheck" || item._type == "multiCheck") {
          if (!("options" in item) || !(item.options instanceof Object)) {
            item.options = {};
          }
          if (!("option" in item.options)) {
            item.options.option = [];
          } else if (!(item.options.option instanceof Array)) {
            if (Object.keys(item.options.option).length == 0) {
              item.options.option = [];
            } else {
              item.options.option = [item.options.option];
            }
          }
        }
        if (item._type == "multiInput" || item._type == "multiCheck") {
          if (!("values" in item) || !(item.values instanceof Object)) {
            item.values = {};
          }
          if (!("value" in item.values)) {
            item.values.value = [];
          } else if (!(item.values.value instanceof Array)) {
            if (Object.keys(item.values.value).length == 0) {
              item.values.value = [];
            } else {
              item.values.value = [item.values.value];
            }
          }
        }
        if (item._type == "complex" || item._type == "multiComplex") {
          if (!("fields" in item) || !(item.fields instanceof Object)) {
            item.fields = {};
          }
          if (!("field" in item.fields)) {
            item.fields.field = [];
          } else if (!(item.fields.field instanceof Array)) {
            if (Object.keys(item.fields.field).length == 0) {
              item.fields.field = [];
            } else {
              item.fields.field = [item.fields.field];
            }
          }
          this.initFields(item.fields.field);
        }
      });
    },

    selectLazyLoad() {
      const oldArray = this.filterArray;
      let pushArray = [];
      if (this.newArray.length === oldArray.length) return;
      if (this.newArray.length + 20 > oldArray.length) {
        pushArray = oldArray.slice(0);
        this.filterArray.splice(0);
      } else {
        pushArray = oldArray.slice(0, 20);
        this.filterArray.splice(0, 20);
      }
      this.newArray = [...this.newArray, ...pushArray];
    },

    filterMethod(params) {
      // 记录下搜索条件
      this.filterVal = params;

      // 搜索方法
      let vals = [];
      let filterArray = [];
      this.brandOptions.forEach((item, i) => {
        // 有搜索条件时  查找符合条件的
        if (params && item.label.includes(params)) {
          // 添加所有符合搜索条件的val值
          vals.push(item.value);
          // 寻找符合搜索条件且newData中没有出现过的数据
          if (!this.newArray.find((fin) => fin.value === item.value)) {
            filterArray.push(item);
          }
        } else if (!params) {
          // 无搜索条件时  获取所有剩余newData中没有出现过的数据
          if (!this.newArray.find((fin) => fin.value === item.value)) {
            filterArray.push(item);
          }
        }
      });

      this.initOption = [...vals];
      this.filterArray = [...filterArray];
      this.selectLazyLoad();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 60%;
  margin:20px auto;
  box-sizing: border-box;

  > div {
    margin-bottom: 10px;
  }
}
</style>